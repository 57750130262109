import { Box, Fade, Slide, Stack, Typography } from '@mui/material'
import React from 'react'
import { DarkDarkGreen, DarkGreen, LightGreen, Name } from '../utils/Constants';
import { Language, Light } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// const BackgroundImage = require('../Assets/Background.jpg')
const BackgroundImage = "";
const GreenLeaf = require('../Assets/GreenLeaf.png');

const HomePage = ({ Language }) => {

  const Navigate = useNavigate();

  return (
    <Box sx={{ paddingTop: '35px', width: '100vw', minHeight: '100vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${BackgroundImage})`, backgroundColor: DarkDarkGreen}}>

      <Box sx={{ minHeight: '50vh', width: '100vw', backgroundColor: '' }}>

        <Stack direction="row">

          <Box sx={{ minHeight: { xs: '80vh', md: '50vh'}, width: { xs: '100vw', md: '50vw'}, backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>

          <Box sx={{ minHeight: '50vh', width: { xs: '80vw', md: '40vw'}, backgroundColor: ''}}>
          <Stack sx={{ minHeight: '50vh', width: { xs: '80vw', md: '41vw'}, backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          
          <Fade in={true} timeout={{ enter: 2500 }} >
          <Typography sx={{ fontSize: '60px', color: LightGreen, fontFamily: 'EllasKitchen1' }}>
            {`${Language === "UK" ? "Welcome to " : "Welkom bij "}`} {Name}.
          </Typography>
          </Fade>
          
          <Fade in={true} timeout={{ enter: 3000 }} >
          <Typography sx={{ fontSize: '40px', color: LightGreen, fontFamily: 'EllasKitchen1' }}>
          {`${Language === "UK" ? "Crafting Happy Beginnings with Every Spoonful!" : "Het creëren van gelukkige beginselen bij elke hap!"}`}
          </Typography>
          </Fade>

            <Fade in={true} timeout={{ enter: 3500 }} direction="right">
            <Stack direction="row" gap={2} sx={{ paddingTop: '30px'}}>

              <Box sx={{ width: { xs: '40vw', md: '220px'}, height: '50px', backgroundColor: DarkGreen, borderRadius: { xs: '10px', md: '10px'}, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', outline: `2px solid ${LightGreen}`, ":hover": { cursor: 'pointer', opacity: '0.8' } }}>
                <Typography sx={{ fontSize: { xs: '30px', md: '40px'}, color: LightGreen, fontFamily: 'EllasKitchen1' }}>
                  4-8 {`${Language === "UK" ? "Months" : "Maanden"}`}
                </Typography>
              </Box>

              <Box sx={{ width: { xs: '40vw', md: '220px'}, height: '50px', backgroundColor: DarkGreen, borderRadius: { xs: '10px', md: '10px'}, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', outline: `2px solid ${LightGreen}`, ":hover": { cursor: 'pointer', opacity: '0.8' } }}>
                <Typography sx={{ fontSize: { xs: '30px', md: '40px'}, color: LightGreen, fontFamily: 'EllasKitchen1' }}>
                  8-12 {`${Language === "UK" ? "Months" : "Maanden"}`}
                </Typography>
              </Box>

            </Stack>
            </Fade>

          </Stack>

          </Box>
          
          </Box>

          <Slide in={true} direction="left" timeout={{ enter: 1000 }}>
            <Box sx={{ minHeight: '100vh', width: '50vw', backgroundColor: '', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${GreenLeaf})`, display: { xs: 'none', md: 'flex'}, alignItems: 'center', justifyContent: 'center', textAlign: 'center', ":hover": { } }}>
              <Box sx={{ width: '40vw', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', paddingRight: '5vw'}}>
              <Fade in={true} timeout={{ enter: 2000 }}>
                <Typography sx={{ fontSize: '65px', color: 'black', fontFamily: 'EllasKitchen1' }}>
                {`${Language === "UK" ? "Nourishing Little Wonders, One Tiny Bite at a Time." : "Koesteren van kleine wonderen, één klein hapje per keer."}`}
                </Typography>
              </Fade>
              </Box>
            </Box>
          </Slide>
        </Stack>

      </Box>

    </Box>
  )
}

export default HomePage