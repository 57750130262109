import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { NavBar } from './components';
import { Contactus, HomePage } from './pages';

const App = () => {

    const [Basket, setBasket] = useState([])
    const [Language, setLanguage] = useState("")

    useEffect(() => {

        const h = localStorage.getItem('Language')
        if(h) {
            setLanguage(h)
        } else {
            setLanguage("DK")
            try {
                localStorage.setItem('Language', "DK")
            } catch (error) {
                console.log(error)
            }
        }



    }, [])

   return (
    <BrowserRouter>
    
    <Box sx={{ }}>
    <NavBar Basket={Basket} Language={Language} setLanguage={setLanguage} />
        <Routes>
            <Route path="/" exact element={<HomePage Language={Language} />} />
            <Route path="/Contact-Us" exact element={<Contactus Language={Language} />} />
        </Routes>
    </Box>
    </BrowserRouter>
   )
}

export default App

