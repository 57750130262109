import { AppBar, Avatar, Box, Button, Container, IconButton, MenuItem, Slide, Stack, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, Typography, styled } from '@mui/material'
import { DataObjectRounded, Light, Menu, Search, ShoppingCart } from '@mui/icons-material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import AdbIcon from '@mui/icons-material/Adb';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DarkDarkGreen, DarkGreen, LightGreen, Name } from '../utils/Constants';

import { GB } from 'country-flag-icons/react/3x2'

const LOGO = require('../Assets/Logo.png');

const pages = [
  {
    Name: 'Products',
    DK: 'Producten',
    URL: '/Products'
  },
  {
    Name: 'Pictures',
    DK: 'Afbeeldingen',
    URL: '/Pictures'
  },
  {
    Name: 'Contact US',
    DK: 'Neem contact met ons op',
    URL: '/Contact-Us'
  },
  {
    Name: 'About',
    DK: 'Over ons',
    URL: '/About'
  }
]

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 5,
      top: 13,
      padding: '0 4px',
    },
  }));

const NavBar = ({ Basket, Language, setLanguage }) => {

  const [MenuOpen, setMenuOpen] = useState(false)
  const Navigate = useNavigate()
  
  return (
    <Box sx={{ position: 'fixed', top: 0, left: 0, zIndex: 1, width: '100vw'}}>
    <Stack>
    <AppBar position="static" sx={{ backgroundColor: DarkDarkGreen, borderBottom: `1px solid ${DarkGreen}` }}>
      <Container maxWidth="xl" >
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex', ":hover": {cursor: 'pointer'} }}} onClick={() => Navigate('/')}>
            <img src={LOGO} style={{ height: '50px', width: '50px', paddingRight: '5px'}} />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: LightGreen,
              textShadow: `-1px 0 ${DarkGreen}, 0 1px ${DarkGreen}, 1px 0 ${DarkGreen}, 0 -1px ${DarkGreen}`,
              textDecoration: 'none',
              ":hover": { cursor: 'pointer' }
            }}
            onClick={() => Navigate('/')}
          >
            {Name}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => {}}
              color="inherit"
            >
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={() => {}}
              onClose={() => setMenuOpen(false)}
              sx={{
                display: { xs: 'block', md: 'none', ":hover": { cursor: 'pointer' } },
              }}
              onClick={() => {
                if(MenuOpen === true) {
                  setMenuOpen(false)
                } else if(MenuOpen === false) {
                  setMenuOpen(true)
                }
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => {}}>
                  <Typography textAlign="center" sx={{ fontFamily: 'EllasKitchen1' }}>{page.Name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none', ":hover": {cursor: 'pointer'} }}} onClick={() => Navigate('/')}>
            <img src={LOGO} style={{ height: '50px', width: '50px', paddingRight: '5px'}} />
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: LightGreen,
              textShadow: `-1px 0 ${DarkGreen}, 0 1px ${DarkGreen}, 1px 0 ${DarkGreen}, 0 -1px ${DarkGreen}`,
              textDecoration: 'none',
              ":hover": {cursor: 'pointer'}
            }}
            onClick={() => Navigate('/')}
          >
            {Name}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, paddingLeft: '100px' }}>
            {pages.map((page) => (
              <Box sx={{ paddingLeft: '5px'}}>
                <Button
                key={page}
                onClick={() => Navigate(page.URL)}
                sx={{ my: 2, color: 'white', display: 'block', ":hover": { textDecoration: 'underline', textDecorationColor: DarkGreen} }}
              >
              <Typography textAlign="center" sx={{ fontFamily: 'EllasKitchen1', fontSize: '20px', color: LightGreen }}>{`${Language === "UK" ? page.Name : page.DK}`}</Typography>
              </Button>
              </Box>
            ))}
          </Box>

          <Stack direction="row" gap={{ xs: 1, md: 5}} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Stack direction="row" gap={1}>
          {/* <Box sx={{ flexGrow: 0, ":hover": { cursor: 'pointer'} }}>
              <Search sx={{ mr: 1 }} />
            </Box> */}
            <Box sx={{ flexGrow: 0, ":hover": { cursor: 'pointer'} }}>
            {/* <StyledBadge badgeContent={Basket.length} color="secondary" sx={{ color: LightGreen}}>
              <ShoppingCart sx={{ mr: 1 }} />
            </StyledBadge> */}
            </Box>

          </Stack>

          <ToggleButtonGroup value={"alignment"} onChange={"handleAlignment"} >
            <ToggleButton value="left" aria-label="left aligned" sx={{ backgroundColor: `${Language === "UK" ? LightGreen : ''}`, ":hover": { backgroundColor: `${Language === "UK" ? LightGreen : ''}`, opacity: '0.8' } }} onClick={() => {
              setLanguage("UK")
              try {
                localStorage.setItem('Language', "UK")
              } catch (error) {
                console.log(error)
              }
            }}>
              <Typography sx={{ fontSize: '20px', color: `${Language === "UK" ? 'black' : LightGreen}`, fontFamily: 'EllasKitchen1' }}>
                UK
              </Typography>
            </ToggleButton>
            <ToggleButton value="justify" aria-label="justified" sx={{ backgroundColor: `${Language === "DK" ? LightGreen : "" }`, ":hover": { backgroundColor: `${Language === "DK" ? LightGreen : ''}`, opacity: '0.8' } }} onClick={() => {
              setLanguage("DK")
              try {
                localStorage.setItem('Language', "DK")
              } catch (error) {
                console.log(error)
              }
            }}>
              <Typography sx={{ fontSize: '20px', color: `${Language === "DK" ? 'black' : LightGreen}`, fontFamily: 'EllasKitchen1' }}>
                DK
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
          </Stack>
          
        </Toolbar>
      </Container>
    </AppBar>
    <Slide in={MenuOpen === true} direction="right">
    <Box sx={{ width: '100vw', minHeight: '100px', backgroundColor: DarkDarkGreen, display: { xs: 'flex', md: 'none'} }}>
    <Stack>
    {pages.map((page) => (
    <MenuItem key={page} onClick={() => {
      setMenuOpen(false)
      Navigate(page.URL)
    }} sx={{ ":hover": { textDecoration: 'underline', textDecorationColor: DarkGreen} }}>
              <Typography textAlign="center" sx={{ fontFamily: 'EllasKitchen1', fontSize: '30px', color: LightGreen }}>{`${Language === "UK" ? page.Name : page.DK}`}</Typography>
    </MenuItem>
    ))}
    </Stack>
    </Box>
    </Slide>
    </Stack>
    </Box>
  )
}

export default NavBar