import React, { useState, useEffect } from 'react';
import { Box, Fade, Slide, Stack, Typography } from '@mui/material'
import { DarkDarkGreen, DarkGreen, LightGreen } from '../utils/Constants';

const SidewaysLeaf = require('../Assets/SidewaysGreenLeaf.png')

const Contactus = ({ Language }) => {

  const [Name, setName] = useState("");
  const [EmailAddress, setEmailAddress] = useState("");
  const [Message, setMessage] = useState("")

  return (
    <Box sx={{ width: '100vw', minHeight: '100vh', backgroundColor: DarkDarkGreen, display: 'flex', justifyContent: 'center', paddingTop: '70px', textAlign: 'center' }}>

      <Stack>

        <Typography sx={{ fontSize: { xs: `${Language === "UK" ? "100px" : "70px"}`, md: '100px'}, color: DarkGreen, fontFamily: 'DharmaGothic', textShadow: `-1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff`, textDecoration: 'underline' }}>
        {`${Language === "UK" ? "CONTACT US" : "CONTACTEER ONS"}`}
        </Typography>
          
        <Stack gap={2} sx={{ width: { xs: '100vw', md: '70vw', xl: '50vw'}}}>
        <input className="contactus-input" onChange={(e) => setName(e.target.value)} style={{ height: '60px', width: '100%', backgroundColor: DarkGreen, outline: `1px solid #fff`, border: 'none', paddingLeft: '10px', fontSize: '20px', color: '#fff' }} placeholder={`${Language === "UK" ? "Name" : "Naam"}`} type="text" />
        <input className="contactus-input" onChange={(e) => setEmailAddress(e.target.value)} style={{ height: '60px', width: '100%', backgroundColor: DarkGreen, outline: `1px solid #fff`, border: 'none', paddingLeft: '10px', fontSize: '20px', color: '#fff' }} placeholder={`${Language === "UK" ? "Email Address" : "E-mailadres"}`} type="email" />
        <textarea className="contactus-input" onChange={(e) => setMessage(e.target.value)} style={{ height: '50vh', width: '100%', backgroundColor: DarkGreen, outline: `1px solid #fff`, border: 'none', paddingLeft: '10px', fontSize: '20px', color: '#fff', paddingTop: '10px' }} placeholder={`${Language === "UK" ? "Message" : "Bericht"}`} type="text" />
        <Box sx={{ width: { xs: '100vw', md: '200px'}, height: '50px', backgroundColor: DarkGreen, borderRadius: { xs: '0', md: '0'}, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', outline: `1px solid #fff`, ":hover": { cursor: 'pointer', opacity: '0.8' } }}>
          <Typography sx={{ fontSize: { xs: '30px', md: '40px'}, color: '#fff', fontFamily: 'EllasKitchen1' }} onClick={() => {
            console.log('Name: ' + Name)
            console.log('Email Address: ' + EmailAddress)
            console.log('Message: ' + Message)
          }}>
            {`${Language === "UK" ? "SUBMIT" : "Indienen"}`}
          </Typography>
        </Box>
        </Stack>

      </Stack>

    </Box>
  )
}

export default Contactus